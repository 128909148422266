import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, finalize, take, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

type HttpMethod = 'get' | 'post' | 'put' | 'delete';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private loadingCount: number = 0;
  private BASE_URL: string = environment.apiUrl;
  public loading: boolean = false;
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private httpClient: HttpClient) {}

  getAccount() {
    return window.location.host.split(':')[0].split('.')[0];
  }

  get<T>(endpoint: string, isSilent: boolean = false): Observable<T> {
    return this.request('get', endpoint, {}, isSilent);
  }

  post<T>(endpoint: string, body: any, isSilent: boolean = false): Observable<T> {
    return this.request('post', endpoint, body, isSilent);
  }

  put<T>(endpoint: string, body: any, isSilent: boolean = false): Observable<T> {
    return this.request('put', endpoint, body, isSilent);
  }

  delete<T>(endpoint: string, isSilent: boolean = false): Observable<T> {
    return this.request('delete', endpoint, {}, isSilent);
  }

  request<T>(method: HttpMethod, endpoint: string, body: any, isSilent: boolean = false): Observable<T> {
    if (!isSilent) {
      this.presentLoading();
    }
    const hasBody = method === 'post' || method === 'put';
    let response: Observable<any> | undefined;
    if (endpoint.indexOf('://') === -1) endpoint = `${this.BASE_URL}${endpoint}`;

    let options = {};
    if (endpoint.indexOf(this.BASE_URL) === 0 || endpoint.indexOf(environment.urlMS) === 0) {
      options = {
        withCredentials: true,
        headers: { 'x-app-role': 'hub-admin' },
      };
    }
    if (hasBody) {
      response = this.httpClient[method](`${endpoint}`, body, options);
    } else {
      response = this.httpClient[method](`${endpoint}`, options);
    }
    return response.pipe(
      catchError((err) => {
        console.error(err);
        return throwError(err);
      }),
      finalize(() => {
        if (!isSilent) {
          this.dismissLoading();
        }
      }),
    );
  }

  private async presentLoading() {
    this.loadingCount++;
    if (this.loadingCount >= 1) {
      this.loading = true;
      this.loading$.next(true);
      // if(this.loadingCount > 0  && this.loading){
      // }
      // if(this.loadingCount <= 0  && this.loading){
      //   this.loading = false;
      // }
    }
  }

  private dismissLoading() {
    this.loadingCount--;
    if (this.loadingCount <= 0 && this.loading) {
      this.loading = false;
      this.loading$.next(false);
    }
  }
}
