<ng-container *ngIf="!auth.isAuthenticated?.getValue()">
  <div class="as-modal">
    <div class="as-modal-overlay"></div>
    <ng-container *ngIf="auth.isLoading()">
      <div class="loader-wrapper">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </ng-container>
    <ng-container *ngIf="!auth.isLoading()">
      <div class="as-modal-content">
        <ng-container *ngIf="!auth.showAlert">
          <ng-container *ngIf="auth.isWaiting">
            <div class="as-modal-header">
              <h4>Waiting</h4>
            </div>
            <div class="as-modal-body">
              <p>We have sent you a Magic Link. Click on the url to access the APP at the email: "{{ signField }}" <br /><a class="text-underline" (click)="resetState('email')">Click here if this is the wrong email</a></p>
              <p *ngIf="sendAgainCounter >= 30"><a class="text-underline" (click)="sign(); sendAgainCounter = 0">Or click here to re-send the code</a></p>
              <p *ngIf="sendAgainCounter < 30">Haven't received the email? Resend in {{ getCurrentTimer() }}s</p>
            </div>
          </ng-container>
          <ng-container *ngIf="!auth.isWaiting && !auth.showPhoneCode">
            <div class="as-modal-header">
              <h4>Sign-in / Signup</h4>
            </div>
            <div class="as-modal-body">
              <!-- <div *ngIf="auth.authServiceData?.settings?.signup_options?.email && auth.authServiceData?.settings?.signup_options?.sms">
                <p>Choose your authentication method:</p>
                <label class="w-50 text-left"><input (click)="focus('email')" class="me-2" name="method" type="radio" value="email" [(ngModel)]="method" />Email</label>
                <label class="w-50 text-left"><input (click)="focus('phone')" class="me-2" name="method" type="radio" value="phone" [(ngModel)]="method" />Phone</label>
              </div> -->
              <div *ngIf="method === 'email'">
                <input #email type="text" [(ngModel)]="signField" placeholder="Type your Email" (keyup)="onEnter($event)" />
              </div>
              <div *ngIf="method === 'phone'">
                <input #phone type="tel" [(ngModel)]="signField" placeholder="Type your Phone" (keyup)="onEnter($event)" />
              </div>
            </div>
            <div class="as-modal-footer pb-0">
              <button [disabled]="!termsAndConditions" (click)="sign()" class="as-button">Sign in / Sign up</button>
            </div>
            <hr class="mx-3 mt-0 bg-white opacity-25" />
            <div class="d-flex mx-2 align-items-center mb-3 mx-3">
              <input type="checkbox" name="termsAndConditions" id="termsAndConditions" [(ngModel)]="termsAndConditions" checked />
              <span class="ms-2 bg-transparent text-white small text-start"> <span class="opacity-50">I agree to the </span> <a (click)="seeTermsAndConditions()" style="cursor: pointer" class="opacity-100 text-white">Terms and Conditions and Privacy Policy</a> </span>
            </div>
          </ng-container>
          <ng-container *ngIf="!auth.isWaiting && auth.showPhoneCode">
            <div class="as-modal-header">
              <h4>Type your code</h4>
            </div>
            <div class="as-modal-body">
              <p>Use the field below to insert the code we've just sent you by SMS at "+{{ signField }}": <br /><a class="text-underline" (click)="resetState('phone')">Click here if this is the wrong number</a></p>
              <input id="phoneCode" type="tel" [(ngModel)]="phoneCode" placeholder="Type your passcode" />
            </div>
            <p *ngIf="sendAgainCounter >= 30"><a class="text-underline" (click)="sign(); sendAgainCounter = 0">Or click here to re-send the code</a></p>
            <p *ngIf="sendAgainCounter < 30">Haven't received the code? Resend in {{ getCurrentTimer() }}s</p>
            <div class="as-modal-footer">
              <button (click)="auth.verifyPhoneCode(phoneCode)" class="as-button">Verify</button>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="auth.showAlert">
          <div class="as-modal-header">
            <h4>Attention</h4>
          </div>
          <div class="as-modal-body">
            {{ auth.alertMessage }}
          </div>
          <div class="as-modal-footer">
            <button (click)="auth.closeAlert()" class="as-button">OK</button>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
