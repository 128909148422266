<div class="drop-zone" [ngStyle]="{'display': !file ? 'flex' : 'initial', 'justify-content': !file ? 'center' : 'initial'}" (dragover)="onDrag($event)" (drop)="onDrop($event)" (click)="removeImage()">
    <label class="file-container" *ngIf="!file">
        <div>
            <div class="d-flex justify-content-center mb-2">
                <img src="/assets/icon/upload.png" alt="img-upload">
            </div>
            <input class="file" type="file" (change)="onChange($event)" />
            <span [innerHTML]="dropZoneLabel"></span>
            <p class="text-center mt-2" [innerHTML]="dropZoneSubLabel"></p>
        </div>
    </label>
    <label class="drop-zone">
        <div class="thumb-delete" id="templates-container" *ngIf="file">
            <img class="thumb" *ngIf="file" [src]="getThumbSrc()" />
        </div>
        <div class="text-center" *ngIf="file">
            <small class="thumb text-primary cursor-pointer mt-1">Edit</small>
        </div>
    </label>
</div>