<div class="modal-header">
  <h5 class="modal-title" id="detailsModalLabel">Account</h5>
  <button type="button" class="btn-close" (click)="modalService.dismissAll()"></button>
</div>
<div class="modal-body pb-0" *ngIf="itemData">
  <form (ngSubmit)="saveData()">
    <div class="form-group mb-3" *ngIf="itemData._id">
      <input class="mb-1" disabled type="text" class="form-control input-group-addon" id="_id" name="_id" [(ngModel)]="itemData._id" placeholder="ID">
    </div>
    <div class="form-group mb-3">
      <label class="mb-1">Name</label>
      <input maxlength="150" type="text" class="form-control input-group-addon" id="name" name="name" [(ngModel)]="itemData.name" (input)="onNameChange()" placeholder="Name">
    </div>
    <div class="form-group mb-3">
      <label class="mb-1">Admin emails (comma separated)</label>
      <input maxlength="150" type="text" class="form-control input-group-addon" id="admin_emails" name="admin_emails" [(ngModel)]="adminEmails" placeholder="Emails" (keyup)="onKeyUp($event)" (blur)="addAdminEmail($event)">
      <div *ngFor="let admin of adminEmailsList" class="badge rounded-pill text-bg-primary cursor-pointer me-1 mt-2">
        {{ admin.identifier?.email }}
        <span class="delete-icon" (click)="deleteEmail(admin.identifier?.email)">x</span>
      </div>
    </div>
    <div class="form-group mb-3" *ngIf="!itemData._id">
      <label class="mb-1">Url</label>
      <div class="d-flex align-items-center justify-content-center">
        <span>https://</span>
        <input type="text" class="form-control input-group-addon" id="slug" name="slug" value="{{itemData.name}}" [(ngModel)]="itemData.slug" placeholder="Url" (input)="onSlugInput($event)">
        <span>.wallet.alphaa.io</span>
      </div>
    </div>

    <div class="form-group mb-3">
      <label class="mb-1">Plan</label>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="paymentMethod" id="STRIPE" [(ngModel)]="selectedPaymentMethod" value="STRIPE" (change)="filterPlans()">
        <label class="form-check-label" for="STRIPE">
          Stripe
        </label>
      </div>
      <select class="form-control form-control input-group-addon input-group-addon" name="plan" id="plan" [(ngModel)]="itemData.plan_id">
        <option *ngIf="!itemData._id" disabled>Select</option>
        <option *ngIf="itemData._id" [value]="itemData.plan">{{itemData.plan}}</option>
        <option *ngFor="let plan of filteredPlans" [value]="plan?._id">{{plan?.name}}</option>
      </select>

      <div *ngIf="!itemData._id">
        <hr>
        <h6>Setup</h6>
        <div class="form-group mb-3">
          <label class="mb-1">Amount</label>
          <input class="form-control form-control input-group-addon" type="tel" name="amount" id="amount" placeholder="Amount" [(ngModel)]="itemData.setup.amount">
        </div>
        <div class="form-group mb-3">
          <label class="mb-1">Total Installments</label>
          <input class="form-control form-control input-group-addon" type="tel" name="total_instalments" id="total_instalments" placeholder="Total Instalments" [(ngModel)]="itemData.setup.total_instalments">
        </div>
        <hr>
        <h6>Billing Data</h6>
        <div class="form-group mb-3">
          <label class="mb-1">First Name</label>
          <input class="form-control form-control input-group-addon" type="text" placeholder="First Name" name="first_name" id="first_name" [(ngModel)]="itemData.billing_data.first_name">
        </div>
        <div class="form-group mb-3">
          <label class="mb-1">Last Name</label>
          <input class="form-control form-control input-group-addon" type="text" placeholder="Last Name" name="last_name" id="last_name" [(ngModel)]="itemData.billing_data.last_name">
        </div>
        <div class="form-group mb-3">
          <label class="mb-1">Email</label>
          <input class="form-control form-control input-group-addon" type="text" placeholder="Email" name="email" id="email" [(ngModel)]="itemData.billing_data.email">
        </div>
        <div class="form-group mb-3">
          <label class="mb-1">Country</label>
          <select class="form-control form-control input-group-addon" id="country" name="country" [(ngModel)]="itemData.billing_data.billing_address.country">
            <option selected disabled>Select</option>
            <option *ngFor="let country of countries" [value]="country.name">{{country.name}}</option>
          </select>
        </div>
      </div>
      <div *ngIf="itemData.invoices?.length">
        <hr>
        <table class="table">
          <thead>
            <tr>
              <th>Installment</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let invoice of itemData.invoices">
              <td>{{invoice.current_instalments}}/{{invoice.total_instalments}}</td>
              <td>{{invoice.amount / 100}}</td>
              <td>{{invoice.status}}</td>
              <td>
                <button type="button" class="btn btn-primary" *ngIf="invoice.status == 'open'" (click)="sendInvoice(invoice)">Send</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer d-flex justify-content-center bg-white">
  <button type="button" class="btn btn-outline-danger" (click)="modalService.dismissAll()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="saveData()" [disabled]="!crudService.hasRequiredFields(itemData,requiredFields)">Save</button>
</div>
