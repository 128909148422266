import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { distinctUntilChanged } from 'rxjs';
import { AuthService } from 'src/app/libs/auth/auth.lib';
import { CrudService } from 'src/app/services/crud/crud.service';
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/services/api/api.service';

export interface IAction{
  label: string,
  icon: string,
  handler: Function,
  dynamicLabel?: (item: any) => string,
  dynamicStyles?: (item: any) => {[k: string]: string}
}
export interface IColumn{
  label: string,
  key: string,
  key_fallback?: string,
  type: string,
  sortable: boolean,
  format?: string
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Output() public onAction: EventEmitter<string> = new EventEmitter<string>();
  @Input() public title:string = '';
  @Input() public source:string = '';
  @Input() public columns:IColumn[] = []
  @Input() public data: any[] = [];
  @Input('modal-component') public modalComponent: any = null;
  @Input() public actions: (IAction|'create'|'edit'|'duplicate'|'delete'|'block'|'user'|'view')[] = [];


  public sortField: string = 'created_at';
  public sortDirection: number = 1;
  public page: any = 1;
  public limit: any = 10;
  public totalDocs: number = 0;
  public searchText: string | undefined;


  constructor(
    public auth: AuthService,
    public crudService: CrudService,
    public apiService: ApiService,
    public modalService: NgbModal,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.auth.isAuthenticated.pipe(distinctUntilChanged()).subscribe((auth)=>{
      if(auth){
        this.readPage(this.page, this.limit);
      }
    })
  }


  public refreshList() {
    this.readPage(this.page, this.limit);
  }
  public pageChange(event: any) {
    this.page = event;
    this.refreshList();
  }

  public sort(field: string) {
    if(!this.columns.find((col)=>col.key === field)?.sortable) return;
    if (this.sortField == field) this.sortDirection = -this.sortDirection;
    this.sortField = field;
    this.refreshList();
  }

  public isActionObject(action: IAction | 'create' | 'edit' | 'duplicate' | 'delete' | 'block' | 'user' | 'view'): action is IAction {
    return typeof action !== 'string';
  }


  public readPage(page: any, size: any) {
    this.crudService.getPage(this.source, page, size, `${this.sortDirection > 0 ? '+' : '-'}${this.sortField}`, this.searchText)
    .subscribe({
      next:(data:any) => {
        console.log(data)
        this.data = data.data;
        this.totalDocs = data.meta.totalDocs
      }
    })
  }

  isUsersRoute(): boolean {
    return this.router.url === '/users';
  }

  public action(item: any): void{
    this.onAction.emit(item);
  }

  public actionHandler(action: IAction|'create'|'edit'|'duplicate'|'delete'|'block'|'user'|'view', item: any = {}): void{
    if(['create','view'].includes(action as string)){
      this.openModal({});
      return;
    }else if(action === 'edit'){
      this.crudService.getbyId(this.source,item._id).subscribe({
        next: (result: any) => {
          const modal = this.modalService.open(this.modalComponent)
          modal.componentInstance.itemData = result
        }
      });
    }else if(action === 'block'){
      confirm('Bloquear usuário')
    }else if(action === 'user'){
      this.router.navigate(['/users/',  item._id], {
        // queryParams: { id: item._id }
     })
    }else if(action === 'duplicate'){
      this.crudService.getbyId(this.source,item._id).subscribe({
        next: (result: any) => {
          this.openModal(result, 'clone');
        }
      });
    }else if(action === 'delete'){
      Swal.fire({
        title: "Are you sure you want to remove this?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#252dff',
        confirmButtonText: 'Yes, remove',
      }).then((result) => {
        if (result.isConfirmed) {
          this.crudService.delete(this.source, item._id).subscribe({
            next: (result) => {
              Swal.fire({
                title: 'Deleted!',
                text: 'Your file has been deleted.',
                icon: 'success',
              }).then(() => {
                this.refreshList();
              });
            },
            error: (error) => {
              if (error) {
                Swal.fire({
                  title: 'Error',
                  text: error.message,
                  icon: 'error',
                });
              } else {
                console.error("An error occurred during deletion:", error);
              }
            }
          });
        }
      });
    } else if(typeof action === 'object'){
      action.handler(item);
    }
  }

  public openModal(data:any, action?: string) {
    const modal = this.modalService.open(this.modalComponent);
    modal.componentInstance.itemData = data;
    modal.componentInstance.totalNfts = this.totalDocs;
    if(action)modal.componentInstance.action = action;
    modal.dismissed.subscribe((result) => {
      if(result?.refreshList){
        this.refreshList();
      }
    });
  }
  public onSearchTextChanged(searchValue: string) {
    this.searchText = searchValue;
    this.page = 1;
    this.refreshList();
  }

  public formatDate(date:string|Date|undefined, format:string = 'DD/MM/YYYY'){
    date = new Date(date ? date : 0);
    let options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    var formattedDate = date.toLocaleDateString(undefined, options);
    return formattedDate;
  }
  public getActionAttribute(action: IAction|'create'|'edit'|'duplicate'|'delete'|'block'|'user'|'view', attr: 'label' | 'icon', item: any = null): string {
    if (typeof action === 'string') {
      return action;
    } else {
      if(attr === 'label' && action.dynamicLabel) return action.dynamicLabel(item);
      return action[attr];
    }
  }
  getTruncatedText(str:string|number|undefined, limit: number = 100, ellipsis: string = '...'): string {
    if(typeof str === 'number') return str.toString()
    if(!str) return '';
    return str.length > limit ? str.substr(0, limit) + ellipsis : str;
  }
}
