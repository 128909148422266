import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  public searchField = new FormControl();
  public enteredSearchValue: string = '';
  public showButton : boolean = true;

  @Output() public searchTextChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    this.searchField.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    )
    .subscribe(term => {
      this.enteredSearchValue = term;
      this.searchTextChanged.emit(this.enteredSearchValue);
    });

  }

  ngOnInit(): void {
  }

  inputChange() {
    this.showButton = this.searchField.value === '';
  }
}
