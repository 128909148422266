import { IAccount } from "../interfaces/account.interface";

export const account: IAccount | any = {
  _id: '',
  name: '',
  slug: '',
  admin_emails: [],
  invoices: [],
  setup: {
    amount: 0,
    total_instalments: 0,
  },
  billing_data: {
    first_name: '',
    last_name: '',
    email: '',
    billing_address: {
      country: '',
    },
  },
  active: false,
  plan_id: ''
};
