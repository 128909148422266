<app-header></app-header>
<div class="container-xxl mt-5">
  <h1 class="my-5">
    Dashboard
  </h1>
</div>

<div class="container-xxl">
  <div class="row">
    <div class="col-lg-9">
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-4 pt-4 w-100 border-0 card d-flex align-items-center rounded-4 shadow" id="chart"></div>
        </div>
        <div class="col-lg-12" *ngIf="usageData">
          <div *ngIf="usageData" class="card shadow rounded-4 border-0 overflow-hidden mb-4">
            <div class="card-header d-flex justify-content-between bg-transparent border-0 pb-0 p-4">
              <h5 class="fw-bold text-secondary m-0">Status</h5>
              <p>Current plan <strong>{{usageData.current_plan.name}}</strong></p>
            </div>
            <div class="card-body mt-5 pb-4">
              <div class="row justify-content-center">
                <div class="col-12 col-sm-12 col-md-4 col-lg-{{idx == 0 ? 2 : 2}} mb-2" *ngFor="let key of usageKeys; let idx = index">
                  <div class="m-0 p-0" role="progressbar" aria-valuenow="67" aria-valuemin="0" aria-valuemax="100" style="--value: {{(usageData.usage[key] / usageData.current_plan[key] * 100).toFixed(0)}}">
                    <p class="fw-bold mb-1">{{key}}</p>
                    <h4 class="text-primary">{{ key === 'nfts' ? (usageData.current_plan[key] === 0 ? '0%' : ((usageData.usage[key]) / usageData.current_plan[key] * 100).toFixed(0) + '%') : (usageData.current_plan[key] === 0 ? '0%' : (usageData.usage[key] / usageData.current_plan[key] * 100).toFixed(0) + '%')}}</h4>
                    <small class="text-grey">{{ key === 'nfts' ? (usageData.usage[key]) : usageData.usage[key] }} / {{ usageData.current_plan[key] }}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 p-0">
      <div class="row m-auto gap-lg-4">
        <div class="col-md-6 col-lg-12">
          <div class="card shadow rounded-4 border-0 overflow-hidden" style="height: 175px;">
            <div class="card-header pb-0 bg-transparent border-0 p-4">
              <h5 class="fw-bold text-secondary m-0">Active Mints</h5>
            </div>
            <div class="card-body pt-0">
              <h1 class="display-3 fw-lighter text-primary">{{dashboardData.totalSupply}}</h1>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-12 mt-4 mt-md-0">
          <div class="card shadow rounded-4 border-0 overflow-hidden" style="height: 175px;">
            <div class="card-header pb-0 bg-transparent border-0 p-4">
              <h5 class="fw-bold text-secondary m-0">Total Users</h5>
            </div>
            <div class="card-body pt-0">
              <h1 class="display-3 fw-lighter text-primary">{{dashboardData.totalUsers}}</h1>
            </div>
          </div>
        </div>
        <div class="col-lg-12 mt-4 mt-lg-0 mb-5">
          <div class="card shadow rounded-4 border-0 overflow-hidden">
            <div class="card-header d-flex gap-2 pb-0 bg-transparent border-0 p-4">
              <h5 class="fw-bold text-secondary m-0">Subscriptions</h5>
              <p>(By plan)</p>
            </div>
            <div class="card-body pt-0">
              <div id="pie-chart"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card shadow rounded-4 border-0 overflow-hidden mb-4 pt-3">
        <div id="profit-chart"></div>
      </div>
    </div>
  </div>
</div>
