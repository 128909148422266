import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth.lib';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private auth:AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                let errMsg = '';
                if (error.error instanceof ErrorEvent) {
                    // Erros do lado do cliente
                    errMsg = `Error: ${error.error.message}`;
                } else {
                    if(error.status === 401) {
                        this.auth.logout();
                    }
                    // Erros do lado do servidor
                    errMsg = `Error Code: ${error.status}, Message: ${error.message}`;
                }
                console.log(errMsg);
                return throwError(error);
            })
        );
    }
}
