import { Component, OnInit, Pipe } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrudService } from 'src/app/services/crud/crud.service';
import { environment } from 'src/environments/environment';
import { plan } from 'src/app/data/plan';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-plans-modal',
  templateUrl: './plans.modal.component.html',
  styleUrls: ['./plans.modal.component.scss']
})
export class PlansModalComponent implements OnInit{
  public itemData = plan;
  public requiredFields: string[] = ['name', 'description', 'duration', 'duration_unit', 'price', 'source', 'active'];
  public showPreview: boolean = false;

  constructor(public crudService: CrudService, public modalService: NgbModal)
  { }

  ngOnInit(): void {
    if (this.itemData && this.itemData.source && this.itemData.duration_unit) {
      this.itemData.source = this.itemData.source
      this.itemData.duration_unit = this.itemData.duration_unit;
    }

    if(this.itemData && !this.itemData.source && !this.itemData.duration_unit){
      this.itemData.source = 'Select'
      this.itemData.duration_unit = 'Select'
    }

    if(!this.itemData._id){
      this.itemData.price = 0
      this.itemData.duration = 0
      this.itemData.usage = {
        nfts: 0,
        storage: 0,
        nft_edit: 0,
        wallets: 0,
        phygitals: 0,
        transfers: 0
      }
    }
  }

  public saveData() {
    if (this.crudService.hasRequiredFields(this.itemData, this.requiredFields)) {
      const _id = this.itemData?._id;
      if (_id != '' && _id != null) {
        this.crudService.update(environment.urlMS + 'membership/plans', _id, this.itemData).subscribe({
          next: (result: any) => {
            Swal.fire("Success!", "Your plan was updated!", "success")
            .then((result) => {
              this.modalService.dismissAll({ refreshList: true })
            })
          },
          error: (err: any) => {
            this.crudService.showError(err);
          }
        });
      } else {
        const { _id, ...payload } = {
          ...this.itemData,
          duration: this.itemData.duration !== null ? Number(this.itemData.duration) : null,
          price: this.itemData.price !== null ? Number(this.itemData.price) : null,
          usage: {
            nfts: Number(plan.usage.nfts),
            storage: Number(plan.usage.storage),
            nft_edit: Number(plan.usage.nft_edit),
            wallets: Number(plan.usage.wallets),
            phygitals: Number(plan.usage.phygitals),
            transfers: Number(plan.usage.transfers),
          },
        };

        this.crudService.create(environment.urlMS + 'membership/plans', payload).subscribe({
          next: (result: any) => {
            Swal.fire("Success!", "Your plan was created!", "success")
            .then((result) => {this.modalService.dismissAll({ refreshList: true })})
          },
          error: (err: any) => {
            this.crudService.showError(err);
          }
        });
      }
    } else {
      Swal.fire("Error!", "Please check your data!", "error");
    }
  }

  transform(value: string | number): number | null {
    if (typeof value === 'string') {
      const numericValue = parseFloat(value);
      return isNaN(numericValue) ? null : numericValue;
    }

    return value;
  }
}
