import { Component } from '@angular/core';
import { IAction, IColumn } from 'src/app/components/list/list.component';
import { AccountsModalComponent } from 'src/app/modals/accounts/accounts.modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent {
  public modalComponent: any = AccountsModalComponent;
  public columns: IColumn[] = [
    { label: 'Image', key: 'settings.theme.color_scheme.light.logo', type: 'image', sortable: false },
    // { label: 'Image', key: 'uploads.image', type: 'image', sortable: false },
    { label: 'Name', key: 'name', type: 'text', sortable: true },
    { label: 'URL Prefix', key: 'slug', type: 'text', sortable: true },
    { label: 'Plan', key: 'plan', type: 'text', sortable: true },
    { label: 'Nfts', key: 'usage.nfts', type: 'text', sortable: true },
    { label: 'Storage', key: 'usage.storage', type: 'text', sortable: true },
    { label: 'Wallets', key: 'usage.wallets', type: 'text', sortable: true },
    { label: 'Phygitals', key: 'usage.phygitals', type: 'text', sortable: true },
    { label: 'Transfers', key: 'usage.transfers', type: 'text', sortable: true },
    { label: 'Active', key: 'title', type: 'switch', sortable: false },
  ]

  public actions:(IAction|'create'|'edit'|'duplicate'|'delete')[] = [
    'create',
    'edit',
  ]
  public accounts: any = environment.urlMS + 'auth/accounts'

  constructor() {}

    ngOnInit() {
    this.account()
    }

    async account(){
      return this.accounts
    }
}
