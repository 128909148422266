import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CategoriesPageComponent } from './pages/categories/categories.component';
import { PlansComponent } from './pages/plans/plans.component';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { DashboardPageComponent } from './pages/dashboard/dashboard.component';
// import { FormsModule } from '@angular/forms';
// import { CommonModule } from '@angular/common';

const routes: Routes = [
  {
    path: '',
    component: DashboardPageComponent,
  },
  {
    path: 'plans',
    component: PlansComponent,
  },
  {
    path: 'accounts',
    component: AccountsComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
