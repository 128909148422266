<div class="container p-0">
  <div class="modal-header">
      <h5 class="modal-title text-black fw-bold" id="detailsModalLabel">Plan</h5>
      <button type="button" class="btn-close" (click)="modalService.dismissAll()"></button>
  </div>
  <div class="modal-body row" *ngIf="itemData">
      <form enctype="multipart/form-data" (ngSubmit)="saveData()">
          <div class="form-group col-12 mb-3">
              <p class="text-grey mb-1">Name</p>
              <input minlength="1" maxlength="150" type="text" class="form-control input-group-addon" id="name" name="name" [(ngModel)]="itemData.name" placeholder="name">
          </div>
          <div class="form-group mb-3">
              <p class="text-grey mb-1">Description</p>
              <textarea minlength="1" maxlength="1000" type="text" rows="5" class="form-control input-group-addon" id="description" name="description" placeholder="Description" [(ngModel)]="itemData.description"></textarea>
          </div>
          <hr>
          <div class="d-flex gap-2">
            <div class="form-group mb-3">
              <p class="text-grey mb-1">NFTs</p>
              <input type="tel" class="form-control input-group-addon" id="nfts" name="nfts" placeholder="NFTs" [(ngModel)]="itemData.usage.nfts" [disabled]="itemData._id">
            </div>
            <div class="form-group mb-3">
              <p class="text-grey mb-1">Storage</p>
              <input type="tel" class="form-control input-group-addon" id="storage" name="storage" placeholder="Storage" [(ngModel)]="itemData.usage.storage" [disabled]="itemData._id">
            </div>
          </div>
          <div class="d-flex gap-2">
            <div class="form-group mb-3">
              <p class="text-grey mb-1">NFT Edit</p>
              <input type="tel" class="form-control input-group-addon" id="nft_edit" name="nft_edit" placeholder="NFT Edit" [(ngModel)]="itemData.usage.nft_edit" [disabled]="itemData._id">
            </div>
            <div class="form-group mb-3">
              <p class="text-grey mb-1">Phygitals</p>
              <input type="tel" class="form-control input-group-addon" id="phygitals" name="phygitals" placeholder="Phygitals" [(ngModel)]="itemData.usage.phygitals" [disabled]="itemData._id">
            </div>
          </div>
          <div class="d-flex gap-2">
            <div class="form-group mb-3">
              <p class="text-grey mb-1">Wallets</p>
              <input type="tel" class="form-control input-group-addon" id="wallets" name="wallets" placeholder="wallets" [(ngModel)]="itemData.usage.wallets" [disabled]="itemData._id">
            </div>
            <div class="form-group mb-3">
              <p class="text-grey mb-1">Transfers</p>
              <input type="tel" class="form-control input-group-addon" id="transfers" name="transfers" placeholder="Transfers" [(ngModel)]="itemData.usage.transfers" [disabled]="itemData._id">
            </div>
          </div>
          <hr>
          <div class="d-flex gap-2">
            <div class="form-group mb-3 w-100">
              <p class="text-grey mb-1">Duration Unit</p>
              <select id="itemData.duration_unit" [(ngModel)]="itemData.duration_unit" class="form-control input-group-addon mt-1" name="itemData.duration_unit" [disabled]="itemData._id">
                <option disabled value="Select" selected>Select</option>
                <option value="day">Day</option>
                <option value="week">Week</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </select>
            </div>
            <div class="form-group mb-3 w-100">
              <p class="text-grey mb-1">Duration</p>
              <input type="tel" class="form-control input-group-addon" id="duration" name="duration" placeholder="Duration" [(ngModel)]="itemData.duration" [disabled]="itemData._id">
            </div>
          </div>
          <div class="d-flex gap-2">
            <div class="form-group mb-3 w-100">
                <p class="text-grey mb-1">Source</p>
                <select id="itemData.source" [(ngModel)]="itemData.source" class="form-control input-group-addon mt-1" name="itemData.source" [disabled]="itemData._id">
                  <option disabled value="Select" selected>Select</option>
                  <!-- <option value="PAYPAL">Paypal</option> -->
                  <option value="STRIPE">Stripe</option>
                </select>
            </div>
            <div class="form-group mb-3 w-100">
              <p class="text-grey mb-1">Price</p>
              <input type="tel" class="form-control input-group-addon" id="price" name="price" placeholder="Price" [(ngModel)]="itemData.price" [disabled]="itemData._id">
            </div>
          </div>
          <div class="form-group d-flex align-items-center gap-5 form-check form-switch mb-3 ps-0">
            <p class="text-grey mb-0">Active</p>
            <input type="checkbox" class=" form-check-input" id="active" name="active" [(ngModel)]="itemData.active">
          </div>
      </form>
  </div>
  <div class="modal-footer justify-content-center bg-white">
      <button type="button" class="btn btn-outline-primary fw-bold" (click)="modalService.dismissAll()">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="saveData()" [disabled]="!crudService.hasRequiredFields(itemData,requiredFields)">Confirm</button>
  </div>
</div>
