import { Component } from '@angular/core';
import { IAction, IColumn } from 'src/app/components/list/list.component';
import { PlansModalComponent } from 'src/app/modals/plans/plans.modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent {

  public plans: string = environment.urlMS + 'membership/plans'
  public modalComponent: any = PlansModalComponent;
  public columns: IColumn[] = [
    { label: 'Title', key: 'name', type: 'text', sortable: true },
    { label: 'Description', key: 'description', type: 'text', sortable: true },
    { label: 'Duration', key: 'duration', type: 'text', sortable: true },
    { label: 'Duration unit', key: 'duration_unit', type: 'text', sortable: true },
    { label: 'Price', key: 'price', type: 'text', sortable: true },
    { label: 'Source', key: 'source', type: 'text', sortable: false },
    { label: 'Active', key: 'active', type: 'switch', sortable: false },
  ]

  public actions:(IAction|'create'|'edit'|'delete')[] = ['create', 'edit', 'delete']

  constructor( ) { }

  ngOnInit() { }


}
