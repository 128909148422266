import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api/api.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class CrudService {
  
  // public urlPrefix:string;
  constructor(private api: ApiService) {}
  
  getList(urlPrefix: string) {
    return this.api.get(urlPrefix);
  }

  getPage(urlPrefix: string, page: any, size: any, sort:string = '', search:string = '') {
    let params = {
      page: page,
      size: size,
      sort: sort,
      q: search
    }
    let querystring = new URLSearchParams(params).toString();
    return this.api.get(`${urlPrefix}?${querystring}`);
  }

  create(urlPrefix: string, item: any) {
    return this.api.post(urlPrefix, item);
  }

  getbyId(urlPrefix: string, itemId: any) {
    return this.api.get(urlPrefix + '/' + itemId);
  }

  update(urlPrefix: string, itemId: any, item: any) {
    return this.api.put(urlPrefix + '/' + itemId, item);
  }

  delete(urlPrefix: string, itemId: any) {
    return this.api.delete(urlPrefix + '/' + itemId);
  }
  toggleActive(urlPrefix: string, item: any) {
    item.active = !item.active;
    return this.api.put(urlPrefix + '/' + item._id, item).subscribe();
  }

  convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  

  
  getSortClass(currentField:string, sortField:string, sortDirection:number){
    if(currentField == sortField){
      if(sortDirection > 0){
        return 'asc';
      }else if(sortDirection < 0){
        return 'desc';
      }
    }
    return '';
  }

  getPaginationPages(totalPages:number){
    let pages = [];
    for (let i = 0; i < totalPages; i++) {
      pages.push(i);
    }
    return pages;
  }

  async onFileSelect(event: any,formField: AbstractControl | string | null | undefined ,attr: string | null = null) {
    const file = event.target.files[0];

    let [, executionValidation] = file.type.split('/');
    let jpg = 'jpg';
    let jpeg = 'jpeg';
    let png = 'png';
    let gif = 'gif';
    let mp4 = 'mp4';

    if ((event.target.files.length > 0 && executionValidation === jpg) || executionValidation === jpeg || executionValidation === png || executionValidation === gif || executionValidation === mp4) {
      
      const base64 = await this.convertBase64(file);

      let payload: any = base64;
      if (attr) {
        payload = typeof formField == 'string' ? formField : formField?.getRawValue();
        if (payload == '' || payload == null) {
          payload = {};
        }
        payload[attr] = base64;
      }
      if(typeof formField != 'string') formField?.setValue(payload);
    } else {
      Swal.fire({
        title: 'Only the following files will be accepted: JPG/PNG/GIF/MP4.',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok!',
      });
      event.target.value = '';
    }
  }
  
  async onFileChange(event: any,  obj:any, formField: string) {
    const file = event.target.files[0];

    let [, executionValidation] = file.type.split('/');
    let jpg = 'jpg';
    let jpeg = 'jpeg';
    let png = 'png';
    let gif = 'gif';
    let mp4 = 'mp4';

    if ((event.target.files.length > 0 && executionValidation === jpg) || executionValidation === jpeg || executionValidation === png || executionValidation === gif || executionValidation === mp4) {
      const base64 = await this.convertBase64(file);
      obj[formField] = base64 as string;
    } else {
      Swal.fire({
        title: 'Only the following files will be accepted: JPG/PNG/GIF/MP4.',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok!',
      });
      event.target.value = '';
    }
  }

  hasRequiredFields(obj: any, requiredFields: string[]): boolean {
    if(!obj) return false;

    for(let i = 0; i < requiredFields.length; i++){
      try {
        let value = this.accessNestedProperty(requiredFields[i], obj);
        if(value == '' || value == null) {
          return false;
        }
      } catch (error) {
        return false;
      }
    }

    return true;
  }

  public accessNestedProperty(key:string, obj:any, key_fallback:string = ''):string|undefined {
    let parts = key.split('.');
    let last:any = parts.pop();
    let res = parts.reduce((o, k) => o && o[k], obj);

    if (res && last in res) {
        return res[last];
    } else {
      if(key_fallback != ''){
        return this.accessNestedProperty(key_fallback, obj);
      }else{
        // console.error('accessNestedProperty', 'Property "' + key + '" does not exist');
        return undefined;
      }
    }
  }

  showError(err:any) {
    let msg = '';
    if(err.message && typeof err.message == 'string'){
      msg = err.message;
    }else if(err.message && err.message[0]?.constraints){
      for(let key in err.message[0]?.constraints){
        msg = err.message[0]?.constraints[key];
      }
    }
    Swal.fire({
      title: 'Error!',
      text: msg,
      icon: 'error',
      confirmButtonText: 'Ok!',
    });
  }

}
