import { APP_INITIALIZER, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CategoriesPageComponent } from './pages/categories/categories.component';
import { HeaderComponent } from './components/header/header.component';
import { SearchComponent } from './components/search/search.component';
import { ListComponent } from './components/list/list.component';
import { LoaderComponent } from './components/loader/loader.component';
import { AuthServiceComponent } from './libs/auth/auth.login.component';
import { ErrorInterceptor } from './libs/auth/interceptors/error-handler.interceptor';
import { AuthService } from './libs/auth/auth.lib';
import { CategoriesModalComponent } from './modals/categories/categories.modal.component';
import { DropZoneComponent } from './components/drop-zone/drop-zone.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PlansComponent } from './pages/plans/plans.component';
import { PlansModalComponent } from './modals/plans/plans.modal.component';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { AccountsModalComponent } from './modals/accounts/accounts.modal.component';
import { DashboardPageComponent } from './pages/dashboard/dashboard.component';

export function initializeApp(authService: AuthService): () => Promise<void> {
  return (): Promise<any> => {
    return new Promise<void>(async (resolve) => {
      await authService.getMe();
      await authService.init();
      resolve();
    });
  };
}

@NgModule({
  declarations: [AppComponent, CategoriesPageComponent, HeaderComponent, SearchComponent, ListComponent, LoaderComponent, AuthServiceComponent, CategoriesModalComponent, DropZoneComponent, PlansComponent, PlansModalComponent, AccountsComponent, DashboardPageComponent, AccountsModalComponent],
  imports: [CommonModule, BrowserModule, HttpClientModule, FormsModule, ReactiveFormsModule, NgbModule, AppRoutingModule, NgxPaginationModule, BrowserAnimationsModule, GoogleMapsModule, CKEditorModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AuthService], multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
