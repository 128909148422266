<div class="modal-header">
    <h5 class="modal-title text-black fw-bold" id="detailsModalLabel">Edit Category</h5>
    <button type="button" class="btn-close" (click)="modalService.dismissAll()"></button>
</div>
<div class="modal-body" *ngIf="itemData">
    <form enctype="multipart/form-data" (ngSubmit)="saveData()">
        <div class="form-group">
            <label class="drop-zone d-flex justify-content-center w-100">
                <div class="form-group">
                    <app-drop-zone *ngIf="!showPreview" class="d-flex justify-content-center" [(file)]="itemData.uploads.banner" [dropZoneLabel]="'Drop image here or browse'" [dropZoneSubLabel]="'JPG/PNG/GIF/PDF'"></app-drop-zone>
                </div>
            </label>
        </div>

        <div class="form-group mb-3">
            <p class="text-grey mb-1">Category name</p>
            <input maxlength="150" type="text" class="form-control input-group-addon" id="title" name="title" [(ngModel)]="itemData.title" placeholder="title">
        </div>
        <div class="form-group mb-3">
            <p class="text-grey mb-1">Subtitle</p>
            <input maxlength="150" type="text" class="form-control input-group-addon" id="subtitle" name="subtitle" placeholder="subtitle" [(ngModel)]="itemData.subtitle">
        </div>
        <div class="form-group mb-3">
            <p class="text-grey mb-1">Description</p>
            <textarea maxlength="1000" type="text" rows="5" class="form-control input-group-addon" id="description" name="description" placeholder="description" [(ngModel)]="itemData.description"></textarea>
        </div>

    </form>
</div>
<div class="modal-footer justify-content-center bg-white">
    <button type="button" class="btn btn-outline-primary fw-bold" (click)="modalService.dismissAll()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="saveData()" [disabled]="!crudService.hasRequiredFields(itemData,requiredFields)">Confirm</button>
</div>
