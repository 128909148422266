import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AuthService } from './auth.lib';
import * as intlTelInput from 'intl-tel-input';
import { catchError } from 'rxjs';
// import { AlertController } from '@ionic/angular';

@Component({
  selector: 'auth-service',
  templateUrl: './auth.login.component.html',
  styleUrls: ['./auth.login.component.scss'],
})
export class AuthServiceComponent implements OnChanges, OnInit {
  @Input('entity') entity: string | undefined;
  @Input('app-id') appId: string | undefined;
  @Input('methods') methods: string[] = ['sms', 'email'];

  @ViewChild('phone') phoneElement: ElementRef | undefined;
  @ViewChild('email') emailElement: ElementRef | undefined;

  public signField: string = '';
  public intlTelInput: any;
  public phoneCode: string | undefined;
  public method: '' | 'email' | 'phone' = 'email';
  public sendAgainCounter: number = 0;
  public sendAgainInterval: any;
  public termsAndConditions: boolean = true;

  constructor(public auth: AuthService) {
    this.updateMethods();
  }

  focus(elId: any): void {
    setTimeout(() => {
      if (elId == 'phone') {
        this.intlTelInput = intlTelInput(this.phoneElement?.nativeElement, { separateDialCode: true });
        this.phoneElement?.nativeElement.addEventListener('keyup', (ev: any) => {
          if (ev.srcElement.value.indexOf('+') >= 0 && ev.srcElement.value.length > 4) {
            ev.srcElement.value = ev.srcElement.value.replace('+' + this.intlTelInput.selectedCountryData?.dialCode, '');
          }
        });
        setTimeout(() => {
          this.phoneElement?.nativeElement.focus();
        }, 200);
      }
    }, 100);
  }
  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes['appId']) this.auth.appId = changes['appId']?.currentValue;
    if (changes['entity']) this.auth.appRole = changes['entity']?.currentValue;
    if (changes['methods']) {
      this.methods = changes['methods']?.currentValue;
      this.updateMethods();
    }
  }

  async seeTermsAndConditions() {
    const terms = this.auth.authServiceData$.getValue().settings?.legalInfo?.terms || "By signing up, you agree to share your email and data, opting in to receive program communications, prizes, and promotions. Users that break the rules of community engagement can be expelled from the program at alphaa.io's discretion.";
    // const alert = await this.alertController.create({
    //   header: 'Terms and Conditions',
    //   message: terms,
    //   buttons: ['OK'],
    //   cssClass: 'custom-alert-zindex',
    // });
    // await alert.present();
  }

  updateMethods() {
    const hasEmail = this.methods.includes('email');
    const hasSms = this.methods.includes('sms');
    if (hasEmail) {
      this.method = 'email';
    } else if (hasSms) {
      this.method = 'phone';
    }
    this.focus(this.method);
  }
  getCurrentTimer() {
    return 30 - this.sendAgainCounter;
  }

  startTimer() {
    if (this.sendAgainInterval) clearInterval(this.sendAgainInterval);
    this.sendAgainCounter = 0;
    this.sendAgainInterval = setInterval(() => {
      this.sendAgainCounter++;
      if (this.sendAgainCounter >= 30) {
        clearInterval(this.sendAgainInterval);
        this.sendAgainCounter = 30;
      }
    }, 1000);
  }
  sign() {
    this.auth
      .sign(this.entity, this.signField, this.method, this.intlTelInput)
      ?.pipe(
        catchError((e: any) => {
          this.resetState(this.method);
          return e;
        }),
      )
      .subscribe({
        next: () => {
          this.startTimer();
        },
      });
  }
  resetState(elId: string) {
    this.sendAgainCounter = 0;
    this.auth.showPhoneCode = false;
    this.auth.isWaiting = false;
    if (elId == 'phone') {
      this.phoneCode = undefined;
      setTimeout(() => {
        this.intlTelInput = intlTelInput(this.phoneElement?.nativeElement, { separateDialCode: true });
      }, 200);
    }
  }

  onEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (!this.auth.showPhoneCode) {
        this.sign();
      }
      if (this.auth.showPhoneCode) {
        this.auth.verifyPhoneCode(this.phoneCode);
      }
    }
  }
}
