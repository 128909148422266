import { Component, EventEmitter, Input, Output } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-drop-zone',
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.scss'],
})
export class DropZoneComponent {
  @Input() public isVideo: boolean = false;
  @Input() public file: string | undefined;
  @Input() public dropZoneLabel: string = '';
  @Input() public dropZoneSubLabel: string = '';
  @Output() public isVideoChange = new EventEmitter<boolean>();
  @Output() public fileChange:EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  getThumbSrc(){
    if(!this.file) return false;
    if(this.file && this.isVideo){
      return 'https://www.openaire.eu/components/com_easyblog/themes/wireframe/images/placeholder-video.png';
    }else{
      return this.file;
    }
  }

  async onChange(event: any) {
    const file = event.target.files[0];
    const upload = await this.handleFileUpload(file);
    if(!upload) event.target.files = [];
  }
  
  async onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0]
    const upload = await this.handleFileUpload(file);
    if(!upload) event.dataTransfer.files = [];
  }

  async handleFileUpload(file:any){

    let [, executionValidation] = file.type.split('/');
    const allowedFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'mp4'];

    if (allowedFileTypes.includes(executionValidation)) {
      const base64 = await this.convertBase64(file);
      this.file = base64 as string;
      if (file.type == 'video/mp4') {
        this.isVideo = true;
      } else {
        this.isVideo = false;
      }
      this.isVideoChange.emit(this.isVideo);
      this.fileChange.emit(this.file);
      return true;
    } else {
      Swal.fire({
        title: 'Only the following files will be accepted: JPG/PNG/GIF/MP4.',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok!',
      });
      return false;
    }
  }


  convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  onDrag(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  removeImage() {
      this.file = undefined;
      this.fileChange.emit(this.file);
  }
}
