import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrudService } from 'src/app/services/crud/crud.service';
import { environment } from 'src/environments/environment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';
import { account } from 'src/app/data/account';
import { countries } from 'src/app/data/countries';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-accounts-modal',
  templateUrl: './accounts.modal.component.html',
  styleUrls: ['./accounts.modal.component.scss'],
})
export class AccountsModalComponent implements OnInit {
  public itemData = account;
  public countries = countries;
  public requiredFields: string[] = ['name', 'slug'];
  public adminEmailsList: any[] = [];
  public adminEmails: string = '';
  public allPlans: any;
  public filteredPlans: any;
  public selectedPaymentMethod: string = 'STRIPE';
  private nameChanges = new Subject<string>();

  constructor(public crudService: CrudService, public modalService: NgbModal, private api: ApiService) {
    this.nameChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe((newName) => {
      if (!this.itemData._id) {
        const slugValue = newName.toLowerCase().replace(/[^a-z0-9_\-]/g, '');
        this.itemData.slug = slugValue;
      }
    });
  }

  ngOnInit(): void {
    this.getAllPlans();
    this.itemData._id ? this.itemData.plan_id = this.itemData.plan_id : this.itemData.plan_id = 'Select';
    if (this.itemData.admins) this.adminEmailsList = this.itemData.admins;
    if (!this.itemData.billing_data) this.itemData.billing_data = {};
    if (!this.itemData.setup) this.itemData.setup = {};
    if (!this.itemData.billing_data.billing_address) this.itemData.billing_data.billing_address = {};
    if (!this.itemData._id) this.itemData.billing_data.billing_address.country = 'United States';
    if (this.itemData._id) this.itemData.plan_id = this.itemData.plan_id;
  }

  sendInvoice(invoice: any) {
    this.api.post(environment.urlMS + 'payment/stripe/invoice', {
        invoice_id: invoice._id,
      }).subscribe({
        next: (result: any) => Swal.fire('Success!', 'Your invoice was successfully sent!', 'success')
      });
  }

  onNameChange() {
    this.nameChanges.next(this.itemData.name);
  }

  async getAllPlans() {
    this.crudService.getList(environment.urlMS + 'membership/plans?page=1&size=10').subscribe({
      next: (result: any) => (this.allPlans = result.data, this.filteredPlans = [...this.allPlans])
    });
  }

  filterPlans() {
    if (this.selectedPaymentMethod === 'STRIPE') {
      this.filteredPlans = this.allPlans.filter((plan: any) => plan.source === 'STRIPE');
    } else if (this.selectedPaymentMethod === 'PAYPAL') {
      this.filteredPlans = this.allPlans.filter((plan: any) => plan.source === 'PAYPAL');
    }
  }

  onSlugInput(event: any) {
    let inputValue: string = event.target.value;
    inputValue = inputValue.toLowerCase().replace(/[^a-z0-9_\-]/g, '');
    this.itemData.slug = inputValue;
  }

  public async saveData() {
    if (this.crudService.hasRequiredFields(this.itemData, this.requiredFields)) {
      const _id = this.itemData?._id;
      if (_id != '' && _id != null) {
        this.itemData.admins = this.adminEmailsList;

        this.crudService.update(environment.urlMS + 'auth/accounts', _id, this.itemData).subscribe({
          next: (result: any) => {
            Swal.fire('Success!', 'Your account was updated!', 'success')
            .then((result) => {this.modalService.dismissAll({ refreshList: true })})
          },
        });
      } else {
        let payload = JSON.parse(JSON.stringify(this.itemData));
        delete payload._id;
        payload.admins = this.adminEmailsList;
        payload.setup.amount = payload.setup.amount

        this.crudService.create(environment.urlMS + 'auth/accounts', payload).subscribe({
          next: (result: any) => {
            Swal.fire('Success!', 'Your account was created!', 'success')
            .then((result) => {this.modalService.dismissAll({ refreshList: true })})
          },
          error: (err: any) => {
            this.crudService.showError(err);
          },
        });
      }
    }else {
    Swal.fire("Error!", "Please check your data!", "error");
    }
  }

  addAdminEmail(event: any) {
    const separatorKeys = [',', ' ', 'Enter'];

    if (this.adminEmails.trim() !== '') {
      const emails = this.adminEmails.split(new RegExp(separatorKeys.join('|')));
      const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      emails.forEach((email) => {
        if (email.trim() !== '' && regexEmail.test(email.trim())) {
          this.adminEmailsList.push(email.trim());
        } else if (email.trim() !== '') {
          Swal.fire('Attention', `Invalid email format: ${email.trim()}`, 'warning');
        }
      });

      this.adminEmails = '';
    }
  }

  deleteEmail(email: string) {
    const index = this.adminEmailsList.indexOf(email);
    if (index !== -1) {
      this.adminEmailsList.splice(index, 1);
    }
  }

  onKeyUp(event: KeyboardEvent) {
    const key = event.key;
    if (key === 'Enter' || key === ' ' || key === ',') {
      this.addAdminEmail(event);
    }
  }
}
