import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { catchError, map, take, tap } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom, of, ReplaySubject, Subject, throwError } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthServiceData, AuthSettings } from './auth.data.interface';

declare var gtag: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public appId: string = 'XXX-XXXX-XXX-XXXXX';
  public appRole: string = '';
  private baseUrl: string = `${environment.urlMS}auth/`;
  public userData: any = {};
  public userData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public authServiceData: any = {};
  public authServiceData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private refreshTokenInProgress = false;
  private refreshTokenSubject: Subject<any> = new ReplaySubject(1);
  private httpOptions: any = {
    observe: 'response',
    withCredentials: true,
    headers: { 'x-app-role': 'hub-admin' },
  };

  public defaultData: AuthServiceData = {
    token: '',
    expireAt: new Date(),
    authenticated: false,
  };

  showAlert: boolean = false;
  alertMessage: string | undefined;
  showPhoneCode: boolean = false;
  isWaiting: boolean = false;
  _loadingCounter: number = 0;

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private location: Location) {}

  isLoading() {
    return this._loadingCounter > 0;
  }

  async init() {
    this.authServiceData = (localStorage['__asData'] && JSON.parse(localStorage['__asData'])) || {};
    this.authServiceData$.next(localStorage['__asData'] && JSON.parse(localStorage['__asData']));
    await this.validate();

    if (this.appRole == 'app' || this.appRole == 'admin') {
      this._loadingCounter++;

      await this.http
        .get(this.baseUrl + 'account/settings', this.httpOptions)
        .pipe(
          tap((response: any) => {
            const res = response.body;
            this._loadingCounter--;
            this.authServiceData.settings = res;
            this.authServiceData$.next(this.authServiceData);
            // let trackingIds = [environment.analyticsTrackingId];
            // if (res.analytics_tracking_id) trackingIds.push(res.analytics_tracking_id);
            // for (let trackingId of trackingIds) {
            //   gtag('config', trackingId);
            //   gtag('event', 'page_view', { send_to: trackingId });
            // }
            this.saveData();
          }),
          catchError((e: any) => {
            this._loadingCounter--;
            return throwError(e);
          }),
        )
        .subscribe();
    } else {
      this.authServiceData.settings = {};
      this.authServiceData$.next(this.authServiceData);
      this.saveData();
    }

    this.route.queryParams.subscribe(async (params) => {
      if (params['as-token']) {
        this._loadingCounter++;
        this.http
          .get(this.baseUrl + 'user/authenticate/' + this.route.snapshot.queryParams['as-token'], this.httpOptions)
          .pipe(
            tap(async (response: any) => {
              const cookie = response.headers.get('Set-Cookie');

              const res = response.body;
              this._loadingCounter--;
              this.saveData(res);
              await this.validate();
              this.removeTokenFromUrl();
              return res;
            }),
            catchError((e: any) => {
              this._loadingCounter--;
              this.removeTokenFromUrl();
              this.presentAlert('Invalid token invalid and/or already used.');
              return throwError(e);
            }),
          )
          .subscribe();
      } else {
        await this.validate();
      }
    });
  }

  async getMe(): Promise<boolean> {
    return await firstValueFrom(
      this.http.get<{ userData: any }>(this.baseUrl + 'user/me', this.httpOptions).pipe(
        map((response: any) => {
          const userData = response.body;
          if (userData) {
            this.authServiceData.authenticated = true;
            this.authServiceData.userData = userData;
            this.userData = userData;
            this.saveData(this.authServiceData);
            return true;
          } else {
            return false;
          }
        }),
        catchError((error) => {
          console.error('Error checking login status', error);
          return of(false);
        }),
      ),
    );
  }

  async getSettings() {
    this.http.get(this.baseUrl + 'account/settings', this.httpOptions).subscribe({
      next: (response: any) => {
        const settings = response.body;
        this.authServiceData.settings = settings;
        this.authServiceData$.next(this.authServiceData);
        this.saveData();
      },
    });
  }

  saveData(data?: AuthServiceData) {
    if (data) {
      this.authServiceData = data;
      if (data.authenticated === true) this.isAuthenticated.next(true);
      this.authServiceData$.next(data);
    }
    this.userData$.next(this.userData);
    localStorage.setItem('__asData', JSON.stringify(this.authServiceData));
  }
  async validate(): Promise<any> {
    let _authServiceData = localStorage['__asData'] && JSON.parse(localStorage['__asData']);
    if (!this.appId || !_authServiceData || !_authServiceData.userData || !_authServiceData.userData._id || !_authServiceData.authenticated) {
      this.authServiceData = {};
      this.isAuthenticated.next(false);
      this.authServiceData$.next({});
    } else {
      this.authServiceData = _authServiceData;
      this.isAuthenticated.next(true);
      this.authServiceData$.next(_authServiceData);
    }
  }

  refreshToken() {
    if (!this.refreshTokenInProgress) {
      this.refreshTokenInProgress = true;

      this._loadingCounter++;
      this.http
        .put(this.baseUrl + 'user/refresh-token', null, this.httpOptions)
        .pipe(
          tap((response: any) => {
            const res = response.body;
            this._loadingCounter--;
            if (res.token && res.refreshToken) {
              this.isAuthenticated.next(true);
            } else {
              this.logout();
            }
          }),
          catchError((e: any) => {
            this._loadingCounter--;
            this.logout();
            return throwError(e);
          }),
        )
        .subscribe();
    }
    return this.refreshTokenSubject.pipe(take(1));
  }

  removeTokenFromUrl() {
    this.route.queryParams.subscribe((params: Params) => {
      if (params['as-token']) {
        let newParams = { ...params };
        delete newParams['as-token'];

        let newUrl = this.router
          .createUrlTree([], {
            relativeTo: this.route,
            queryParams: newParams,
            queryParamsHandling: '',
          })
          .toString();
        this.location.replaceState(newUrl);
      }
    });
  }

  async logout(redirectTo: string | boolean = false) {
    this.clearData();
    this.validate();
    if (redirectTo) this.router.navigateByUrl(redirectTo as string, { onSameUrlNavigation: 'reload' });
  }
  clearData() {
    this._loadingCounter--;
    this.isWaiting = false;
    this.showPhoneCode = false;
    this.authServiceData = JSON.parse(JSON.stringify(this.defaultData));
    this.authServiceData$.next(this.defaultData);
    this.showAlert = false;
    this.alertMessage = undefined;
    this.isAuthenticated.next(false);
    this.saveData(this.defaultData);
  }

  sign(entity: string | undefined, data: any, fieldName: string, intlTelInput: any = false) {
    if (!entity) return;
    if (fieldName == 'phone') {
      this.showPhoneCode = true;
      let dialCode = '+' + intlTelInput?.selectedCountryData?.dialCode;
      data = dialCode + data;
    } else if (fieldName == 'email') {
      this.isWaiting = true;
    }
    let payload: any = {
      redirect_url: window.location.href,
      identifier: data,
      identifier_type: fieldName,
    };
    this._loadingCounter++;
    return this.http.post(this.baseUrl + 'user/sign-in/' + entity, payload, this.httpOptions).pipe(
      tap((response: any) => {
        const res = response.body;
        this._loadingCounter--;
        if (res.authData) {
          setTimeout(() => {
            let phoneElement = document.getElementById('phoneCode');
            phoneElement?.focus();
          }, 100);
        }
      }),
      catchError((e: any) => {
        this._loadingCounter--;
        if (e.error?.message) {
          this.presentAlert(e.error.message);
        } else {
          this.presentAlert('error');
        }
        this.showPhoneCode = false;
        this.isWaiting = false;

        return throwError(e);
      }),
    );
  }

  verifyPhoneCode(phoneCode: string | undefined) {
    this._loadingCounter++;
    this.http
      .get(this.baseUrl + 'user/authenticate/' + phoneCode, this.httpOptions)
      .pipe(
        tap(async (response: any) => {
          const res = response.body;
          this._loadingCounter--;
          await this.validate();
          this.saveData(res);
          this.showPhoneCode = false;
          return res;
        }),
        catchError((e: any) => {
          this._loadingCounter--;
          this.presentAlert('invalid_token');
          return throwError(e);
        }),
      )
      .subscribe();
  }
  async updateSettings(settings?: AuthSettings) {
    this._loadingCounter++;
    await this.http.put(this.baseUrl + 'account/settings', settings, this.httpOptions);
    this._loadingCounter--;
  }

  presentAlert(msg: string) {
    this.showAlert = true;
    this.alertMessage = msg;
  }
  closeAlert() {
    this.showAlert = false;
    delete this.alertMessage;
  }
}
