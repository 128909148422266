import { IPlan } from "../interfaces/plan.interface";

export const plan: IPlan = {
  _id: '',
  name: '',
  description: '',
  source: '',
  price: 0,
  duration: 0,
  duration_unit: '',
  active: false,
  usage: {
    nfts: 0,
    storage: 0,
    nft_edit: 0,
    wallets: 0,
    phygitals: 0,
    transfers: 0
  }
}
