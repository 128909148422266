import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ApiService } from './services/api/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  public showLoading:boolean = false;

  constructor(public api:ApiService, private cdRef:ChangeDetectorRef) {
    
  }

  ngOnInit() {
    this.api.loading$.subscribe({
      next: (resp:boolean) => {
        setTimeout(() => {
          this.showLoading = resp;
          this.cdRef.detectChanges();
        });
      }
    });
  }
}
