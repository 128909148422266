<nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="container-fluid box-nav px-4">
        <div class="d-flex align-items-center">
            <a [href]="'/'" class="navbar-brand d-flex align-items-center text-secondary fw-bold">
                <img src="/assets/icon/logo-alphaaio.png" class="me-1" alt="logo-alphaaio">Admin 3.0
            </a>
        </div>
        <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="navbar-collapse d-lg-flex justify-content-end collapse" id="navbarNav">
              <ul class="subnav d-lg-flex align-items-center mb-0">
                  <li>
                    <a [routerLink]="['/']"    [routerLinkActive]="router.url == '/' ? 'actived' : 'not-Active'" class="text-black fw-regular">
                        Dashboard
                    </a>
                  </li>
                <li>
                  <a [routerLink]="['/plans']" [routerLinkActive]="['actived']" class="text-black fw-regular">
                    Plans
                  </a>
                </li>
                  <li>
                    <a [routerLink]="['/accounts']" [routerLinkActive]="['actived']" class="text-black fw-regular">
                        Accounts
                    </a>
                </li>
              </ul>
          </div>
    </div>
</nav>
