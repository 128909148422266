import { Component, OnInit } from '@angular/core';
import { IColumn } from 'src/app/components/list/list.component';
import { CategoriesModalComponent } from 'src/app/modals/categories/categories.modal.component';

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss']
})
export class CategoriesPageComponent implements OnInit {

    public modalComponent: any = CategoriesModalComponent;
    public columns: IColumn[] = [
      { label: 'Image', key: 'uploads.banner', key_fallback: 'uploads .banner', type: 'image', sortable: false },
      // { label: 'Image', key: 'uploads.image', type: 'image', sortable: false },
      { label: 'Title', key: 'title', type: 'text', sortable: true },
      { label: 'URL', key: 'href', type: 'text', sortable: true },
      { label: 'CTA', key: 'cta', type: 'text', sortable: true },
      { label: 'Active', key: 'title', type: 'switch', sortable: false },
    ]
  

    constructor(
    ) {
    }

    ngOnInit() {
    }

}
