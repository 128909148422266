import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ICategory } from 'src/app/interfaces/category.interface';
import { CrudService } from 'src/app/services/crud/crud.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-categories-modal',
  templateUrl: './categories.modal.component.html',
  styleUrls: ['./categories.modal.component.scss']
})
export class CategoriesModalComponent implements OnInit{
  public itemData: ICategory|null = null
  public requiredFields: string[] = ['uploads.banner'];
  public showPreview: boolean = false;

  constructor(
    public crudService: CrudService,
    public modalService: NgbModal
  ) { }

  ngOnInit(): void {
    console.log(this.itemData)
  }

  public saveData() {
  
    if (this.crudService.hasRequiredFields(this.itemData,this.requiredFields)) {
      const _id = this.itemData?._id;
      if (_id != '' && _id != null) {
        this.crudService.update('categories',_id, this.itemData).subscribe({
          next: (result:any) => {
            this.modalService.dismissAll({refreshList: true});
            Swal.fire("Success!", "Your category was updated!", "success");
          },
          error: (err:any) => {
            this.crudService.showError(err);
          }
        });
      } else {
        let payload = JSON.parse(JSON.stringify(this.itemData));
        delete payload._id;

        console.log(payload, ' create')
        this.crudService.create('categories', payload).subscribe({
          next: (result:any) => {
            // this.categoriesList.refreshList();
            this.modalService.dismissAll({refreshList: true});
            Swal.fire("Success!", "Your category was created!", "success");
          },
          error: (err:any) => {
            this.crudService.showError(err);
          }
        });
      }
    } else {
      // this.errormsg = "Your form is invalid..."
    }
  }

}
