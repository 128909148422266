import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { CrudService } from 'src/app/services/crud/crud.service';
import Swal from 'sweetalert2';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import { AuthService } from 'src/app/libs/auth/auth.lib';
import { distinctUntilChanged } from 'rxjs';
import { environment } from 'src/environments/environment';

Highcharts.setOptions({
  colors: ['#71FFB2', '#071333', '#252DFF'],
});

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardPageComponent implements OnInit {
  dashboardData: any = '';
  usageData: any = '';
  usageKeys: string[] = [
    'nfts',
    // 'nft_edit',
    'phygitals',
    'storage',
    'transfers',
    'wallets',
  ];
  loaders: any = {
    MintsPerDay: false,
    MintsPerHash: false,
    MintsPerTemplates: false,
    MintsPerUser: false,
    Ranking: false,
  };
  chartOptions: any = {
    chart: {
      height: 350,
      type: 'column',
      renderTo: 'chart',
      style: {
        backgroundColor: 'transparent',
      },
    },
    credits: { enabled: false },
    title: {
      text: 'Overview',
      style: {
        fontWeight: 'bold',
      },
      align: 'left',
    },
    legend: {
      layout: 'horizontal',
      align: 'right',
      verticalAlign: 'top',
      x: 0,
      y: -10,
      floating: true,
    },
    xAxis: {
      categories: [],
      stackLabels: {
        enabled: true,
      },
    },
    yAxis: {
      opposite: true,
      min: 0,
      title: {
        enabled: false,
      },
    },
    plotOptions: {
      column: {
        maxPointWidth: 5,
        pointPadding: 0,
      },
    },
    series: [
      {
        color: '#71FFB2',
        name: 'Mints',
        data: [],
      },
      {
        color: '#252DFF',
        name: 'Active users',
        style: {
          align: 'center',
        },
        data: [],
      },
    ],
  };
  profitChartOptions: any = {
    chart: {
      width: '1200',
      height: 350,
      renderTo: 'profit-chart',
      type: 'area', // Define o tipo básico como 'area'
    },
    credits: { enabled: false },
    title: {
      text: 'Revenues vs. AWS Costs',
    },
    xAxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      plotBands: [
        {
          from: 7.5,
          to: 11,
          color: '#EEE',
          label: {
            text: 'Forecast',
            style: {
              color: '#071333',
            },
          },
        },
      ],
    },
    yAxis: [
      {
        title: {
          text: 'USD',
        },
      },
      {
        title: {
          text: 'GA Pageviews',
        },
        opposite: true, // Alinha este eixo à direita
      },
    ],
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: [0, 0, 0, 300],
          stops: [
            [0, '#252DFFFF'],
            [1, '#252DFF00'],
          ],
        },
      },
    },
    series: [
      {
        name: 'Revenues',
        data: [
          1900, 2000, 2100, 2200, 2400, 2600, 2700, 2900, 3000, 3100, 3200,
          3500,
        ],
        type: 'area',
      },
      {
        name: 'AWS Costs',
        data: [
          559.34, 535.22, 847.2, 1340.74, 1399.23, 1168.78, 746.62, 800, 810,
          820, 830, 840,
        ],
        type: 'line',
        marker: {
          enabled: true,
        },
      },
      {
        name: 'Pageviews',
        data: [
          500, 600, 750, 800, 900, 1000, 1100, 1200, 1300, 1400, 1450, 1500,
        ],
        type: 'line',
        yAxis: 1, // Utiliza o segundo eixo y (índice 1)
        marker: {
          enabled: true,
        },
      },
    ],
  };

  pieChartOptions: any = {
    chart: {
      width: 274,
      height: 274,
      type: 'pie',
      renderTo: 'pie-chart',
      style: {
        backgroundColor: 'transparent',
      },
    },
    credits: { enabled: false },
    title: {
      text: '',
      enabled: false,
    },
    plotOptions: {
      pie: {
        dataLabels: [{ enabled: false }],
      },
    },
    series: [
      {
        name: 'Plans',
        data: [],
      },
    ],
  };

  constructor(
    public api: ApiService,
    public auth: AuthService,
    public crudService: CrudService
  ) {}
  async downloadReport(mode: string) {
    if (this.loaders[mode]) return;
    this.loaders[mode] = true;
    this.api.get('dashboard/reports/' + mode).subscribe({
      next: (result: any) => {
        if (result) {
          var element = document.createElement('a');
          element.setAttribute(
            'href',
            'data:text/csv;charset=utf-8,' + encodeURIComponent(result.content)
          );
          element.setAttribute('download', mode + '.csv');

          element.style.display = 'none';
          document.body.appendChild(element);

          element.click();

          document.body.removeChild(element);
          this.loaders[mode] = false;
        }
      },
    });
  }
  ngOnInit(): void {
    this.auth.isAuthenticated.pipe(distinctUntilChanged()).subscribe((auth) => {
      if (auth) {
        this.init();
      }
    });
  }

  async init() {
    this.dashboardData = {};

    const req: any = await this.api.get(environment.urlMS + 'loyalty/hub/overview').toPromise();
    const response = {
      chartData: {
        xAxis: req?.xAxis || [],
        users: req?.users || [],
        supply: req?.supply || [],
        plans: req?.plans || [],
      },
    };

    this.pieChartOptions.series = [{
      name: 'Plans',
      data: []
    }];

    for (let i in response.chartData?.plans) {
      const plan = response.chartData.plans[i];
      this.pieChartOptions.series[0].data.push({
        name: plan.name,
        y: plan.y
      });
    }

    // for (let i in response.chartData?.xAxis) {
    //   // if(response.chartData.supply[parseInt(i)-1]){
    //   //   response.chartData.supply[i] += (response.chartData.supply[parseInt(i)-1] + Math.round((response.chartData.supply[i] + (1000 * Math.random()) )));
    //   //   response.chartData.users[i] += (response.chartData.users[parseInt(i)-1] + Math.round((response.chartData.users[i] + (1000 * Math.random()) )));
    //   // }
    // }

    for (let i in response.chartData?.xAxis) {
      if (response.chartData?.xAxis[i] > '2023-03-31') {
        let date = response.chartData?.xAxis[i];
        this.chartOptions.xAxis.categories.push(date);
        this.chartOptions.series[0].data.push(response.chartData.supply[i]);
        this.chartOptions.series[1].data.push(response.chartData.users[i]);
      }
    }
    this.dashboardData.totalUsers =
      response.chartData.users[response.chartData.users.length - 1] || 0;
    this.dashboardData.totalSupply =
      response.chartData.supply[response.chartData.supply.length - 1] || 0;

    this.usageData = {
      current_plan: {
        name: '',
        nfts: this.dashboardData.totalSupply,
        wallets: this.dashboardData.totalUsers,
        phygitals: 0,
        // "nft_edit": 5000,
        transfers: 100,
        storage: 2.5,
      },
      usage: {
        nfts: this.dashboardData.totalSupply,
        wallets: this.dashboardData.totalUsers,
        phygitals: 0,
        // "nft_edit": 23,
        transfers: 0,
        storage: 2.5,
      },
    };

    this.initChart();
  }

  initChart() {
    let chart = Highcharts.chart(this.chartOptions);
    chart.xAxis[0].options.title?.text;

    let pieChart = Highcharts.chart(this.pieChartOptions);
    let profitChart = Highcharts.chart(this.profitChartOptions);
  }
}
